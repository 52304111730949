@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap");

body {
  background-color: #fffdee;
  height: 100vh;
  font-family: "Fuzzy Bubbles", cursive;
  font-weight: bold;
}

.card {
  transform-style: preserve-3d;
  position: relative;
  transition: all 0.5s ease;
}

.card.hide {
  transform: rotateY(180deg);
}

.front {
  position: absolute;
  backface-visibility: hidden;
}

.back {
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
